<template>
	<div class="clouds">
		<div v-for="cloud in clouds" class="cloud" :class="[ cloud.slug, { flipX: cloud.flipX, flipY: cloud.flipY } ]" :key="cloud.id" v-hammer:tap="() => explode_cloud(cloud)">
			<div class="shadow"></div>
			<div class="cloud-wrap">
				<div class="cloud-content" :id="cloud.id"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRandomID, sample, random } from '@/js/utils/utils'
import lottie from 'lottie-web'
import Sounds from '@/js/utils/sounds'

const animations = [ 'anim-1', 'anim-2', 'anim-3', 'anim-4' ]

export default {
	name: 'clouds',
	data() {
		return {
			clouds: []
		}
	},
	computed: {
		...mapGetters([ 'world' ]),
	},
	methods: {
		explode_cloud(cloud) {
			if (cloud && cloud.anim) {
				cloud.anim.play()
				setTimeout(() => {
					Sounds.play('cloud')
				}, 400)
			}
		},
		add_cloud() {
			let exists = this.clouds.map((c) => c.slug)
			let slugs = animations.filter((a) => !exists.includes(a))
			if (slugs) {
				let slug = sample(slugs)
				this.clouds.push({
					id: 'c' + getRandomID(),
					slug,
					url: sample([ 'cloud1.json', 'cloud2.json' ]),
					anim: null,
					flipX: sample([ true, false ]),
					flipY: sample([ true, false ])
				})
				this.refresh_anims()
			}
		},
		refresh_anims() {
			this.$nextTick(() => {
				this.clouds.forEach((cloud) => {
					if (!cloud.anim) {
						cloud.anim = lottie.loadAnimation({
							container: this.$el.querySelector('#' + cloud.id),
							animationData: require('@/assets/img/map/' + cloud.url),
							renderer: 'svg',
							loop: false,
							autoplay: false,
							rendererSettings: { preserveAspectRatio: 'xMidYMax slice' }
						})
						cloud.anim.addEventListener('complete', (e) => {
							cloud.anim.destroy()
							cloud.anim = null
							this.clouds = this.clouds.filter((c) => c.id !== cloud.id)
							this.$nextTick(() => {
								setTimeout(() => {
									this.add_cloud()
								}, random(10000, 5000))
							})
						})
					}
				})
			})
		}
	},
	mounted() {
		this.add_cloud()
		setTimeout(this.add_cloud, random(3000, 1000))
		setTimeout(this.add_cloud, random(4000, 2000))
		setTimeout(this.add_cloud, random(5000, 3000))
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.clouds
	position absolute
	left 0
	top 0
	.cloud
		position fixed
		left 0
		width (200px * 1.15)
		height (120px * 1.15)
		border-radius 50%
		opacity 1
		cursor pointer
		animation_speed = 1
		&.anim-1
			top 20%
			left 55%
			animation anim-1 (100s * animation_speed) linear infinite
			// animation-delay (5s * animation_speed)
			> .cloud-wrap > .cloud-content
				animation grow 10s linear infinite
		&.anim-2
			top 6%
			left -12%
			animation anim-2 (90s * animation_speed) linear infinite
			// animation-delay (1s * animation_speed)
			> .cloud-wrap > .cloud-content
				animation grow 15s linear infinite
		&.anim-3
			top 40%
			left 20%
			animation anim-3 (95s * animation_speed) linear infinite
			// animation-delay (14s * animation_speed)
			> .cloud-wrap > .cloud-content
				animation grow 12s linear infinite
		&.anim-4
			top 60%
			left 45%
			animation anim-4 (90s * animation_speed) linear infinite
			// animation-delay (11s * animation_speed)
			> .cloud-wrap > .cloud-content
				animation grow 12s linear infinite
		&.flipX
			> .cloud-wrap
				transform scaleX(-1)
		&.flipY
			> .cloud-wrap
				transform scaleY(-1)
		&.flipX.flipY
			> .cloud-wrap
				transform scaleX(-1) scaleY(-1)
		> .cloud-wrap
			position absolute
			width round(450px * 1.25)
			height round(400px * 1.25)
			left 'calc(50% - %s)' % round(@width / 2)
			top 'calc(50% - %s)' % round(@height / 2)
			pointer-events none
			// background-color alpha(blue, 20%)
			> .cloud-content
				absolute 0
				>>> svg
					pointer-events none
		.shadow
			position absolute
			left 10%
			top 70%
			height 60%
			width 80%
			background-color alpha(#000, 25%)
			border-radius 50%
			filter blur(8px)


@keyframes grow
	0%, 100%
		transform scale(1)
	25%
		transform scale(0.85)
	50%
		transform scale(1.05)
	75%
		transform scale(0.85)

@keyframes anim-1
	0%
		transform none
		opacity 0
	5%
		opacity 1
	95%
		opacity 1
	100%
		opacity 0
		transform translate3d(1000px, 200%, 0)

@keyframes anim-2
	0%
		transform none
		opacity 0
	5%
		opacity 1
	95%
		opacity 1
	100%
		opacity 0
		transform translate3d(1000px, 300%, 0)

@keyframes anim-3
	0%
		transform none
		opacity 0
	5%
		opacity 1
	95%
		opacity 1
	100%
		opacity 0
		transform translate3d(1000px, -50%, 0)

@keyframes anim-4
	0%
		transform none
		opacity 0
	5%
		opacity 1
	95%
		opacity 1
	100%
		opacity 0
		transform translate3d(1000px, -50%, 0)

</style>
