<template>
	<div class="waves">
		<div v-for="i in 10" :key="i" class="wave"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'waves',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'world' ]),
	},
	methods: {
		
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.waves
	absolute 0
	.wave
		position absolute
		background url('../../assets/img/map/wave.gif') center center no-repeat
		background-size contain
		width (310px / 2.5)
		height (115px / 2.5)
		&:nth-child(1)
			left 24%
			top 24%
		&:nth-child(2)
			left 5%
			top 65%
		&:nth-child(3)
			left 1%
			top 40%
		&:nth-child(4)
			left 30%
			top 58%
		&:nth-child(5)
			left 40%
			top 65%
		&:nth-child(6)
			left 60%
			top 50%
		&:nth-child(7)
			left 63%
			top 76%
		&:nth-child(8)
			left 80%
			top 29%
		&:nth-child(9)
			left 90%
			top 70%
		&:nth-child(10)
			left 88%
			top 3%

</style>
