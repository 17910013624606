<template>
	<div class="world-map" ref="map"
		v-hammer:panstart="(event)=> move_map_start(event)"
		v-hammer:pan="(event)=> move_map(event)"
		v-hammer:panend="(event)=> move_map_end(event)"
	>
		<div class="map-content" ref="map_content" :style="{ width: map_size.width + 'px', height: map_size.height + 'px' }">
			<sea />
			<!-- <waves /> -->
			<div class="layer shadow">
				<img src="@/assets/img/map/light.png">
				<img src="@/assets/img/map/shadow-1.png">
				<!-- <img src="@/assets/img/map/shadow-2.png"> -->
			</div>
			<div class="layer root">
				<img v-if="background" :src="background" @load="set_world_loaded()">
			</div>

			
			<!-- <div class="layer"><img src="@/assets/img/map-africa.svg"></div> -->
			<!-- <div class="layer"><div class="test-1" ref="test1"></div></div> -->
			<!-- <div class="layer"><div class="test-2" ref="test2"></div></div> -->
			<div v-if="show_cities" class="layer cities">
				<world-map-pin
					v-for="city in world.cities"
					:city="city"
					:key="city.id"
					v-hammer:tap="()=> show_city(city)"
				/>
			</div>
			<clouds />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import lottie from 'lottie-web'
import Sea from '@/components/world/sea'
import Waves from '@/components/world/waves'
import Clouds from '@/components/world/clouds'
import WorldMapPin from '@/components/world/world-map-pin'

export default {
	name: 'world-map',
	data() {
		return {
			original_map_size: { height: 1400, width: 2490 },
			map_size: {
				height: 0,
				width: 0
			},
			loaded: 0
		}
	},
	props: {
		show_cities: { default: true }
	},
	computed: {
		...mapGetters([ 'world' ]),
		background() {
			return require('@/assets/img/map/continents.png')
			if (this.world.map_assets && this.world.map_assets.length) {
				let media = this.world.map_assets.find((m) => {
					if (m && m.tags && m.tags.length) return m.tags.includes('world_map')
				})
				return this.$medias.get({ id: media.id })
			}
			return false
		}
	},
	watch: {
		loaded(newValue) {
			if (newValue >= 1) {
				// 500ms de délai pour le temps d'insertion dans le dom après le chargement de l'image en mémoire
				setTimeout(() => { this.$emit('load') }, 500)
			}
		}
	},
	methods: {
		show_city(city) {
			this.$router.push({ name: 'city', params: { city_slug: city.slug } })
		},
		set_world_loaded() {
			this.loaded++
		},
		get_move_coords(e, final) {
			let map = this.$el
			let map_content = this.$refs.map_content
			let x = 0
			let y = 0

			if (map_content) {
				x = (parseFloat(map_content.getAttribute('data-x')) || 0) + e.deltaX
				y = (parseFloat(map_content.getAttribute('data-y')) || 0) + e.deltaY

				if (final) {
					x = x + (e.velocityX * 100)
					y = y + (e.velocityY * 100)
				}

				// To down
				if (y < 0) { 
					if (y < -(map_content.clientHeight - map.clientHeight)) {
						y = -(map_content.clientHeight - map.clientHeight)
					}
				}
				// To top
				else if (y > 0) { 
					y = 0
				}

				// To left
				if (x < 0) { 
					if (x < -(map_content.clientWidth - map.clientWidth)) {
						x = -(map_content.clientWidth - map.clientWidth)
					}
				}
				// To right
				else if (x > 0) {
					x = 0
				}
			}

			return { x, y }
		},
		move_map_start(e) {
			if (this.$refs.map_content) {
				this.$refs.map_content.classList.remove('is-decelerating')
			}
		},
		move_map(e) {
			if (this.$refs.map_content) {
				let coords = this.get_move_coords(e, false)
				this.$refs.map_content.style.transform = 'translate3d(' + coords.x + 'px, ' + coords.y + 'px, 0)'
			}
		},
		move_map_end(e) {
			if (this.$refs.map_content) {
				let map_content = this.$refs.map_content
				let coords = this.get_move_coords(e, true)

				map_content.classList.add('is-decelerating')
				map_content.style.transform = 'translate3d(' + coords.x + 'px, ' + coords.y + 'px, 0)'
				setTimeout(() => {
					map_content.classList.remove('is-decelerating')
				}, 400)

				map_content.setAttribute('data-x', coords.x)
				map_content.setAttribute('data-y', coords.y)
			}
		},
		resize() {
			let map = this.$el
			this.map_size.height = map.clientHeight
			let w = this.map_size.height * this.original_map_size.width / this.original_map_size.height
			this.map_size.width = w

			if (w < map.clientWidth) {
				this.map_size.height = map.clientWidth * this.original_map_size.height / this.original_map_size.width
				this.map_size.width = map.clientWidth
			}
			this.move_map({ deltaX: 0, deltaY: 0 })
		},
	},
	mounted() {
		this.resize()
		window.addEventListener('resize', this.resize)

		// a.addEventListener('DOMLoaded', () => { this.loaded++ })

		// let a = lottie.loadAnimation({
		// 	container: this.$refs.test1,
		// 	path: 'test-idle.json',
		// 	renderer: 'svg',
		// 	loop: true,
		// 	autoplay: true,
		// 	rendererSettings: { preserveAspectRatio: 'xMidYMax slice' }
		// })
		// a.addEventListener('DOMLoaded', () => { this.loaded++ })

		// let b = lottie.loadAnimation({
		// 	container: this.$refs.test2,
		// 	path: 'test-idle.json',
		// 	renderer: 'svg',
		// 	loop: true,
		// 	autoplay: true,
		// 	rendererSettings: { preserveAspectRatio: 'xMidYMax slice' }
		// })
		// b.addEventListener('DOMLoaded', () => { this.loaded++ })
	},
	components: { Sea, Waves, Clouds, WorldMapPin }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.world-map
	height 100%
	overflow hidden
	.map-content
		width 1920px
		height 1080px
		&.is-decelerating
			transition transform 0.4s easeOutQuart
		.layer
			position absolute
			left 0
			top 0
			height 100%
			width 100%
			&.shadow
				img
					absolute 0
			img
				height 100%
				width auto
			.test-1
				position absolute
				left 20%
				top 20%
				width 20%
				height 20%
			.test-2
				position absolute
				left 30%
				top 30%
				width 20%
				height 20%

</style>
