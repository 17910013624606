<template>
	<div class="sea">
		<canvas ref="canvas" :width="width + 'px'" :height="height + 'px'"></canvas>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { random, sample } from '@/js/utils/utils'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

const square_height = 48
const square_width = 64
let canvas = null
const colors = [ '#73D3C2', '#6cd6c0', '#5BC5B5', '#4AB9A9', '#43A9A5', '#3F9D9F', '#30878E', '#367690' ]

let request_animation_id = null
let tiles = []

export default {
	name: 'sea',
	data() {
		return {
			width: 0,
			height: 0,
			manageEventListeners: manageEventListeners(),
		}
	},
	computed: {
		...mapGetters([ 'world' ]),
	},
	methods: {
		generate_tiles() {
			tiles = []
			let nw = Math.ceil(this.width / square_width)
			let nh = Math.ceil(this.height / square_height)
			for (let x = 0; x <= nw; x++) {
				for (let y = 0; y <= nh; y++) {
					tiles.push(this.generate_tile(x, y))
				}
			}
		},
		generate_tile(x, y) {
			let color_index_start = random(colors.length - 1)
			let tile = {
				id: x + '-' + y,
				x,
				y,
				color_start: colors[color_index_start],
				orientation: random(1),
				opacity: random(100)
			}

			let color_index_end = color_index_start + 4
			if (color_index_end > colors.length - 1) color_index_end = color_index_end - (colors.length - 1)
			tile.color_end = colors[color_index_end]

			return tile
		},
		draw_sea() {
			canvas.clearRect(0, 0, this.width, this.height)

			tiles.forEach((tile) => {
				this.draw_tile(tile)
			})

			request_animation_id = window.requestAnimationFrame(this.draw_sea)
		},
		draw_tile(tile) {
			if (tile.orientation === 1) tile.opacity += 1
			else if (tile.orientation === 0) tile.opacity -= 1

			if (tile.orientation === 1 && tile.opacity > 99) {
				tile.orientation = 0
				// tile.opacity = 100
			}
			if (tile.orientation === 0 && tile.opacity < 1) {
				tile.orientation = 1
				// tile.opacity = 0
			}

			// if (tile.id === '0-0') {
			// 	console.log(tile.opacity)
			// }

			canvas.globalAlpha = 1
			canvas.fillStyle = tile.color_start
			canvas.fillRect(tile.x * square_width, tile.y * square_height, square_width, square_height)

			canvas.globalAlpha = tile.opacity / 100
			canvas.fillStyle = tile.color_end
			canvas.fillRect(tile.x * square_width, tile.y * square_height, square_width, square_height)

		},
		resize() {
			this.width = this.$el.clientWidth
			this.height = this.$el.clientHeight
			this.generate_tiles()
		}
	},
	mounted() {
		this.manageEventListeners.destroy()
		this.manageEventListeners.add(window, 'resize', this.resize)
		this.$nextTick(() => {
			canvas = this.$refs.canvas.getContext("2d")
			this.resize()
			this.draw_sea()
		})
	},
	beforeDestroy() {
		if (request_animation_id) cancelAnimationFrame(request_animation_id)
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

&.sea
	absolute 0
	background-color #4AB9A9
	canvas
		width 100%
		height 100%
		opacity 0.25

</style>
