<template>
	<div class="city-stars" :class="[ { arc }, 'nb-' + stars.length, size ]">
		<div v-for="(star, index) in stars" :key="index" class="star" :class="[ star.medal ]"><img :src="star.img" alt="Médaille"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
const medals = [ 'gold', 'silver', 'bronze' ]

export default {
	name: 'city-stars',
	props: {
		city: { default: null },
		arc: { default: false },
		size: { default: 'normal' },
		empty_color: { default: 'white' }
	},
	computed: {
		...mapGetters([ 'user', 'user_gold' ]),
		stars() {
			let stars = []
			this.city.clientGames.forEach((game) => {
				let medal = this.user.achievement.rewardsAchieved.filter((r) => {
					return r.clientReward && 
						r.clientReward.rewardableElementId === game.id &&
						r.status === 'completed' &&
						r.clientReward.clientLootItem &&
						r.clientReward.clientLootItem.tags.includes('medal')
					})
					.map(x => x.clientReward.clientLootItem.tags)
					.map(tags => tags.reduce((a, tag) => medals.includes(tag) ? tag : a, null))
					.reduce((a, medal) => {
						a[medals.indexOf(medal)] = medal
						return a
					}, [])
					.reduce((a, medal) => (a ? a : (medal ? medal : a)), null)
				if (!medal) medal = 'empty'
				let tags = [ 'star', medal ]
				if (medal === 'empty') tags.push(this.empty_color)

				stars.push({
					medal,
					img: this.$medias.find({ tags })
				})
			})
			return stars
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
// @import '../../assets/css/city_color'


.city-stars
	display flex
	align-items center
	justify-content center
	height 100%

	&.small
		&.arc
			&.nb-3
				.star
					&:nth-child(1), &:nth-child(3)
						transform translateY(10px)
			&.nb-4
				.star
					&:nth-child(1)
						transform translate(6px, 20px)
					&:nth-child(4)
						transform translate(-6px, 20px)
			&.nb-5
				.star
					&:nth-child(2), &:nth-child(4)
						transform translateY(10px)
					&:nth-child(1)
						transform translate(16px, 38px)
					&:nth-child(5)
						transform translate(-16px, 38px)
		.star
			width 24px
			height 24px
			background-size @width
			margin 0 2px
			> img
				height calc(100% - 4px)
				width calc(100% - 4px)

	&.normal
		&.arc
			&.nb-3
				.star
					&:nth-child(1), &:nth-child(3)
						transform translateY(16px)
			&.nb-4
				.star
					&:nth-child(1)
						transform translate(6px, 20px)
					&:nth-child(4)
						transform translate(-6px, 20px)
			&.nb-5
				.star
					&:nth-child(2), &:nth-child(4)
						transform translateY(10px)
					&:nth-child(1)
						transform translate(16px, 38px)
					&:nth-child(5)
						transform translate(-16px, 38px)
					
	&.big .star
		width 48px
		height 48px
		background-size @width
		margin 0 4px
		> img
			height calc(100% - 6px)
			width calc(100% - 6px)
	
					
	.star
		flex center center
		height 32px
		width 32px
		background url('../../assets/img/star-white.svg') center center no-repeat
		background-size @width
		margin 0 3px
		> img
			height calc(100% - 4px)
			width calc(100% - 4px)
	
			
</style>