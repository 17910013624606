<template>
	<div v-if="city" class="world-map-pin" :class="[city.slug, { locked: !unlocked, unlockable: unlockable }]">
		<div class="city-icon" v-if="city_icon"><img :src="city_icon"></div>
		<div v-if="criteria" class="tooltip">{{ criteria }} <ui-icon :tags="[ 'resource', 'diamond', 'white' ]" :size="12" /></div>
		<city-stars v-if="unlocked" :city="city" :arc="true" size="small" />
		<!-- <div v-if="unlocked" class="stars" :class="'nb-' + city.clientGames.length">
			<div v-for="game in city.clientGames" class="star" :class="{ full: has_gold_medal(game) }"></div>
		</div> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CityStars from '@/components/world/city-stars'
import {getValueOnPath} from "../../js/utils/utils"

export default {
	name: 'world-map-pin',
	data() {
		return {

		}
	},
	props: {
		city: { default: null }
	},
	computed: {
		...mapGetters([ 'user', 'user_gold' ]),
		city_icon() {
			return this.$medias.find({
				clientMedias: this.city.clientMedias,
				tags: ['city_icon']
			})
		},
		criteria() {
			let n = 0
			if (this.city.clientCriterias && this.city.clientCriterias.length) {
				let criteria = this.city.clientCriterias.find((c) => {
					return c.clientLootItem.slug == 'gold'
				})
				if (criteria) n = criteria.amount
			}
			return n
		},
		unlocked() {
			if (!this.city.clientCriterias.length) return true
			let criteria = this.city.clientCriterias.find((c) => { return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug == 'gold' })
			if (criteria && criteria.id && (getValueOnPath(this, 'user.achievement.criteriasAchieved') || []).find(achieve => achieve.criteriaId === criteria.id))
				return true
			return false
		},
		unlockable() {
			if (!this.unlocked) {
				let criteria = this.city.clientCriterias.find((c) => {
					return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable && c.clientLootItem.slug == 'gold'
				})
				if (criteria && criteria.amount <= this.user_gold) return true
			}
			return false
		}
	},
	methods: {
		has_gold_medal(game) {
			let criteria = game.clientCriterias.find((c) => {
				return c.action == 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId == c.id })
			})
			if (criteria) return true
			return false
		}
	},
	components: { CityStars }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.world-map-pin
	position absolute
	left 20%
	top 20%
	width 40px
	height 40px
	background-color #FBAB00
	border-radius 50%
	border 3px solid #fff
	box-shadow 0px 8px 20px alpha(#000, 50%)
	cursor pointer
	cursor pointer
	&.montreal
		left 22%
		top 17%
	&.melbourne
		left 87%
		top 65%
	&.london
		left 39.5%
		top 11%
	&.paris
		left 43.5%
		top 15%
	&.tokyo
		left 80.5%
		top 22%
	&.seattle
		left 10%
		top 20%
	&.locked
		border 3px solid alpha(#fff, 50%)
		background-color #6C6C6C
		.city-icon
			opacity 0.5
		.tooltip
			display flex
			color alpha(#fff, 50%)
			.ui-icon
				opacity 0.5
	&.locked.unlockable
		border-color #fff
		background-color #6C6C6C
		animation unlockable 1s linear infinite
		.city-icon
			opacity 1
		.tooltip
			color #fff
			.ui-icon
				opacity 1
	.city-icon
		width 100%
		height 100%
		display flex
		align-items center
		justify-content center
		opacity 1
		> img
			width 100%
			height 100%
			object-fit contain
	.tooltip
		position absolute
		left 50%
		top -32px
		height 24px
		transform translateX(-50%)
		line-height @height
		background-color #6C6C6C
		box-shadow 0 0 0 1px alpha(#fff, 10%)
		border-radius 24px
		padding 0 10px
		color #fff
		white-space nowrap
		font-weight 700
		align-items center
		justify-content center
		display none
		.ui-icon
			margin 0 0 0 4px
			opacity 1
	>>> .city-stars
		position absolute
		left 50%
		bottom calc(100% + 6px)
		height 24px
		// width 80px
		// background-color alpha(red, 50%)
		flex center center
		transform translateX(-50%)
		// &.nb-2 .star
		// 	&:nth-child(1)
		// 		transform translate(-65%, 4px)
		// 	&:nth-child(2)
		// 		transform translate(65%, 4px)
		// &.nb-3 .star
		// 	&:nth-child(1)
		// 		transform translate(-100%, 12px)
		// 	&:nth-child(3)
		// 		transform translate(100%, 12px)
		// &.nb-4 .star
		// 	&:nth-child(1)
		// 		transform translate(-140%, 24px)
		// 	&:nth-child(2)
		// 		transform translate(-65%, 4px)
		// 	&:nth-child(3)
		// 		transform translate(65%, 4px)
		// 	&:nth-child(4)
		// 		transform translate(140%, 24px)
		// &.nb-5 .star
		// 	&:nth-child(1)
		// 		transform translate(-160%, 40px)
		// 	&:nth-child(2)
		// 		transform translate(-120%, 12px)
		// 	&:nth-child(4)
		// 		transform translate(120%, 12px)
		// 	&:nth-child(5)
		// 		transform translate(160%, 40px)
		// .star
		// 	position absolute
		// 	left calc(50% - 12px)
		// 	top -8px
		// 	height 24px
		// 	width 24px
		// 	background url(../../assets/img/star-white.svg) center center no-repeat
		// 	background-size 24px auto
		// 	margin 0 1px
		// 	// &:nth-child(2)
		// 	// 	transform translateY(-8px)
		// 	&.full
		// 		&:after
		// 			content ''
		// 			position absolute
		// 			left 0
		// 			top 0
		// 			right 0
		// 			bottom 0
		// 			background url(../../assets/img/star-yellow.svg) center center no-repeat
		// 			background-size 20px auto



@keyframes unlockable
	0%, 100%
		transform scale(1)
	50%
		transform scale(1.1)

</style>
